












































import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
import Util from '../../../../lib/util'
import AbpBase from '../../../../lib/abpbase'
import FieldMetadata from "@/store/entities/jobcontext/fieldmetadata";

@Component
export default class FieldMetadataForm extends AbpBase {
    @Prop({type: Boolean, default: false}) value: boolean;

    fieldMetadata: FieldMetadata = new FieldMetadata();
    color: string = "";

    data() {
        return {
            positionTypes: [],
            sectionNumbers: [],
            companyCategories: [],
            color: ''
        }
    };

    positionTypes: any;
    sectionNumbers: any;
    companyCategories: any;

    async mounted() {
        this.positionTypes = await this.$store.dispatch('fieldMetadata/positionTypes');
        this.sectionNumbers = await this.$store.dispatch('fieldMetadata/sectionNumbers');
        this.companyCategories= await this.$store.dispatch('category/lookUp');
    }

    isCompanyField(){
        return this.fieldMetadata.lookUpType === 0;
    }

    created() {
    }

    save() {
        (this.$refs.fieldMetadataForm as any).validate(async (valid: boolean) => {
            if (valid) {
                this.fieldMetadata.color = this.color;
                await this.$store.dispatch({
                    type: 'fieldMetadata/update',
                    data: this.fieldMetadata
                });
                (this.$refs.fieldMetadataForm as any).resetFields();
                this.$emit('save-success');
                this.$emit('input', false);
            }
        })
    }

    cancel() {
        (this.$refs.fieldMetadataForm as any).resetFields();
        this.$emit('input', false);
    }

    visibleChange(value: boolean) {
        if (!value) {
            this.$emit('input', value);
        } else {
            this.fieldMetadata = Util.extend(true, {}, this.$store.state.fieldMetadata.editFieldMetadata);
            this.color = this.fieldMetadata.color;
        }
    }
}
